import type { NextPage } from "next";
import Head from "next/head";
import styles from "../styles/Home.module.css";

const Home: NextPage = () => {
  return (
    <div key="app" className={styles.container}>
      <Head>
        <title>xms-api</title>
        <meta name="description" content="xms-api" />
      </Head>
    </div>
  );
};

export default Home;
